import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`While I was solving `}<a parentName="p" {...{
        "href": "https://leetcode.com/problems/boats-to-save-people/"
      }}>{`881. Boats to Save People`}</a>{`, I found out a special issue on Rust.`}</p>
    <p>{`The following solution should work normally in other languages like Java.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rs"
      }}>{`impl Solution {
    pub fn num_rescue_boats(people: Vec<i32>, limit: i32) -> i32 {
        let mut people = people;
        people.sort_unstable();

        let mut count = 0;
        let mut i = 0;
        let mut j = people.len() - 1;

        while i <= j {
            if people[i] + people[j] <= limit {
                i += 1;
            }
            j -= 1;
            count += 1;
        }

        count
    }
}
`}</code></pre>
    <p>{`However, the program panics on the following test case:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rs"
      }}>{`#[test]
fn example3() {
    let actual = Solution::num_rescue_boats(vec![3, 5, 3, 4], 5);
    let expected = 4;
    assert_eq!(actual, expected);
}

// thread '...' panicked at 'attempt to subtract with overflow' ...
`}</code></pre>
    <p>{`The reason is that `}<inlineCode parentName="p">{`j`}</inlineCode>{` is an `}<inlineCode parentName="p">{`usize`}</inlineCode>{` which cannot be subtracted below 0.`}</p>
    <p>{`I found out a smart solution that deals with the edge case elegantly. It counts all people first
and then subtract count when there are more than 1 people can take the boat.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rs"
      }}>{`impl Solution {
    pub fn num_rescue_boats(people: Vec<i32>, limit: i32) -> i32 {
        let mut people = people;
        people.sort_unstable();

        let mut count = people.len() as i32;
        let mut i = 0;
        let mut j = people.len() - 1;

        while i < j {
            if people[i] + people[j] <= limit {
                i += 1;
                count -= 1;
            }
            j -= 1;
        }

        count
    }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      